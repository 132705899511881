import * as React from "react";
import { withLayout } from "../components/Layout";
// import authorPhoto from "../images/tomerlichtash.jpg";

const AboutPage = () => {
  return (
    <div style={{ minHeight: "80vh" }}>
      <div className="ui container aboutContentSegment">
        {/* todo */}
      </div>
    </div>
  );
};

export default withLayout(AboutPage);
